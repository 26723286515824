@font-face {
  font-family: glacialIndifference;
  src: url(../../Assets/glacial-indifference/GlacialIndifference-Regular.otf);
}
@font-face {
  font-family: montserrat-bold;
  src: url(../../Assets/montserrat/Montserrat-Bold.otf);
}
@font-face {
  font-family: montserrat;
  src: url(../../Assets/montserrat/Montserrat-Light.otf);
}
.home-parent {
  font-size: 10vmin;
  margin-top: 20px;
  font-family: glacialIndifference;
}
.home {
  width: 100%;
  height: 41vw;
  display: flex;
  justify-content: center;
  padding: 0 0 20px 0;
}

.home .inner-div {
  position: absolute;
  top: 30%;
  z-index: 20;
  left: 2%;
  width: 40%;
  height: min-content;
  border-radius: 35px;
  /* margin: auto auto 5vw 5%; */
  background-color: rgb(9, 23, 148);
  color: white;
}
.home .inner-div ul {
  list-style: none;
  text-align: left;
  width: 90%;
}

.home .inner-div ul li h4 {
  /* margin: 5% 0px; */
  font-size: 50px;
  margin: 0;
}

.home .inner-div ul li p {
  margin: 5% 0 0 0;
  font-size: 20px;
  font-weight: 500;
  word-spacing: 2px;
  line-height: 170%;
}
.incubations {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  /* width: 100%; */
}

.incubation-partners {
  width: 30%;
  object-fit: contain;
}
.home-view2 {
  width: 100%;
  display: flex;
  /* flex-direction: column-reverse; */
  flex-wrap: wrap;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid black; */
}

.home-view2-images {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid green; */
  padding: 2%;
}

.home-view2-images .subimage {
  width: 100%;
  height: 100%;
}

.home-view2-content {
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  padding: 2%;
  align-items: center;
  /* border: 1px solid red; */
}

.home-view2-content :first-child {
  font-size: 75%;
}

.home-view2-content :nth-child(2) {
  font-size: 35%;
  /* line-height: 1.5rem; */
  /* word-spacing: 0.3rem; */
  padding: 2rem;
}

.home-view2-button {
  width: 10rem;
  height: 9%;
  background-color: rgba(45, 138, 192, 0.849);
  box-shadow: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  color: antiquewhite;
  font-weight: 700;
  word-spacing: 0.1rem;
  letter-spacing: 0.101rem;
  font-size: 1.1rem;
}

.home-view2-images img {
  height: 40%;
  width: 40%;
  object-fit: contain;
  /* position: relative; */
}

.home-view3 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 20px 0;
  word-wrap: break-word;
  align-items: center;
  justify-content: space-between;
}

.home-view3-content :first-child {
  font-size: 2.5rem;
}

.home-view3-content :nth-child(2) {
  font-size: 1.3rem;
  margin: 50px 0;
}

.home-view3 .course-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  width: 90%;
}

.home-view4-content :first-child {
  font-size: 2.5rem;
}

.home-view4-content :nth-child(2) {
  font-size: 1.5rem;
}

.home-view4 {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 70px 0;
}

.home-view4 .services-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.home-view5 {
  width: 100%;
  padding: 5% 0;
}

.home-view5-content {
  background-color: rgb(34, 10, 78);
}

.home-view5-text {
  padding: 30px 5%;
  width: 45%;
  font-size: 2rem;
  text-align: left;
  font-weight: 800;
  color: white;
  font-family: montserrat-bold;
}

.home-view5-text :last-child {
  font-family: montserrat;
  font-size: 0.8rem;
  line-height: 25px;
  font-weight: 600;
  word-spacing: 0.4rem;
}

.home-view5-clients {
  display: flex;
  flex-direction: row;
  padding: 100px 25px 50px 25px;
  justify-content: space-evenly;
}

.mentors-carousel {
  display: flex;
  align-items: center;
  width: 100%;
}

.home-view6 .mentors-title {
  font-size: 3rem;
}

.home-view7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2.5rem;
  background-color: #4777ff;
  color: aliceblue;
  padding: 50px 0px;
  font-family: glacialIndifference;
}

.home-view7 .partners-carousel {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.partner-logo {
  object-fit: contain;
  width: 10%;
}

@media screen and (max-width: 700px) {
  .home > .inner-div {
    position: static;
    /* left: 50%; */
    width: 100%;
    height: fit-content;
    border-radius: 0;
  }

  .home .inner-div ul li {
    font-size: 42px;
  }

  .home .inner-div ul li p {
    font-size: 17px;
    /* line-height: 1.5%; */
  }

  .home-view2 {
    margin-top: 80%;
  }

  .home-view2-content {
    width: 100%;
  }

  .home-view2-content :first-child {
    font-size: 24px;
    font-weight: 550;
  }

  .home-view2-content :nth-child(2) {
    font-size: 18px;
    /* line-height: 1.5rem; */
    /* word-spacing: 0.3rem; */
    padding: 10px;
  }

  .home-view3-content :first-child {
    font-size: 24px;
    font-weight: 550;
  }

  .home-view3-content :nth-child(2) {
    font-size: 18px;
    margin: 50px 0;
  }

  .course-list {
    flex-wrap: wrap;
  }

  .services-list {
    flex-wrap: wrap;
    gap: 20px;
  }

  .home-view5-clients {
    flex-wrap: wrap;
    gap: 100px;
  }

  .home-view5-text {
    width: 100%;
  }
}
