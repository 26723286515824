.carousel-container-partners {
  width: 90%;
  padding: 30px 0;
}

.partner-img-container {
  display: flex;
  height: 170px;
  /* width: 230px; */
  /* width: auto; */
  /* margin: 0 1px; */
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
}

.partner-img {
  /* object-fit: contain; */
  width: 100%;
  /* border: 1px solid green; */
  padding: 0 10px;
  /* margin: 0 10px; */
  /* height: auto; */
}

.exception {
  padding: 0 30px;
}
