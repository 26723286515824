.mentor-container {
  width: 80%;
}

.mentor-img {
  object-fit: contain;
  width: 100%;
  box-shadow: 1px 1px 5px grey;
}

.mentor-description {
  box-sizing: border-box;
  padding: 1px 0px;
  width: 100%;
  /* height: 30vh; */
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.336);
  position: relative;
  bottom: 112px;
  color: white;
}

@media screen and (max-width: 700px) {
  .mentor-description {
    bottom: 105px;
  }
}
