.agents-container {
  border-top: 4px solid greenyellow;
  font-size: 1rem;
  width: 70%;
  height: fit-content;
  background-color: rgb(255, 255, 255);
  padding: 0px 50px 10px 50px;
  box-shadow: 1px 1px 5px grey;
}

.agent-image {
  position: relative;
  object-fit: contain;
  width: 70px;
  border-radius: 50%;
  bottom: 40px;
  z-index: 999;
}

.agent-name {
  margin-top: -10px;
  margin-bottom: 35px;
  font-size: 1.2rem;
}

.agent-designation {
  margin-top: 35px;
  margin-bottom: 20px;
  font-size: 1rem;
}

.agent-feedback {
  font-size: 1rem;
}

@media screen and (max-width: 700px) {
  .agents-container {
    width: 90%;
    padding: 0px 10px 10px 10px;
  }
}
