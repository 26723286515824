.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: green;
  gap: 10px;
}
.check {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
  display: flex;
}

.check > img {
  position: relative;
  bottom: 2px;
  left: 3px;
}
