.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 40px 0px;
  background-color: azure;
}

.footer-intro {
  width: 20%;
}

.footer-intro :first-child {
  letter-spacing: 2px;
  font-size: 2rem;
  text-align: left;
  font-weight: 300;
}

.footer-intro :last-child {
  text-align: left;
  font-size: 0.95rem;
  color: rgb(54, 54, 54);
}

.footer-more {
  text-align: left;
  color: rgb(54, 54, 54);
}

.footer-more > p {
  font-size: 1.3rem;
  color: rgb(0, 0, 0);
  font-weight: 400;
}

.footer-more > div p {
  cursor: pointer;
}

.footer-contact {
  text-align: left;
  color: rgb(54, 54, 54);
}

.footer-contact :first-child {
  color: rgb(0, 0, 0);
  font-size: 1.3rem;
  font-weight: 400;
}

.footer-more-mobile {
  margin-top: 20px;
  width: 70%;
  display: flex;
  justify-content: space-around;
  color: rgb(54, 54, 54);
  font-size: small;
  /* border: 1px solid green; */
}

@media screen and (max-width: 700px) {
  .footer-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-intro {
    width: 90%;
  }

  .footer-contact {
    padding: 0 5%;
  }
}
