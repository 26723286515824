.course {
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  width: 25%;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 5px grey;
}

.images {
  max-width: 100%;
}

.image-container {
  width: 100%;
  flex: 1;
  object-fit: contain;
}

.content-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 90%;
}

.course-level {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  /* margin: -5px 0 0 0; */
  font-size: 30%;
}

.level-box {
  height: 5px;
  width: 80px;
  background-color: rgb(71, 69, 69);
  border-radius: 20px;
}

.course-type {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-start;
  width: 90%;
  gap: 20px;
  flex-wrap: wrap;
  /* border: 1px solid black; */
}

.course-type > p {
  color: rgb(30, 131, 30);
}

.courses-button {
  width: 7rem;
  background-color: rgba(45, 138, 192, 0.849);
  box-shadow: none;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  color: antiquewhite;
  font-weight: 600;
  font-size: 1rem;
  padding: 5px;
  margin-bottom: 15px;
}

@media screen and (max-width: 700px) {
  .course {
    width: 95%;
  }
}
