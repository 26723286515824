.carousel-container {
  width: 100%;
  padding: 0px 3vw;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

@media screen and (max-width: 700px) {
  .carousel-container {
    padding: 0px 9vw;
  }
}
