.services-container img {
  object-fit: contain;
  width: 95px;
}

.services-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  width: 21%;
  border-top: 7px solid red;
  border-radius: 3px;
  padding: 30px 30px 50px 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: 1px 1px 5px grey;
}

.services-container :nth-child(2) {
  font-size: 1.2rem;
}

@media screen and (max-width: 700px) {
  .services-container {
    width: 80%;
    font-size: 1rem;
  }
  .services-container img {
    width: 20vw;
  }
}
