.side-menu-parent {
  display: flex;
  flex: 1;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 52, 52, 0.4);
  align-items: center;
  justify-content: center;
  flex-direction: row;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
}

.side-menu {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 72%;
  position: fixed;
  top: 0;
  left: 100%;
  background-color: rgba(236, 226, 226, 0.877);
  border: 1px solid green;
  z-index: 11;
}
