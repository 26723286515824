.agents-carousel-container {
  width: 100%;
  padding: 40px 60px;
}

@media screen and (max-width: 700px) {
  .agents-carousel-container {
    padding: 40px 25px;
  }
}
