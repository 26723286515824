@font-face {
  font-family: Belleza;
  src: url(../../Assets/Belleza-Regular.ttf);
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  background-color: transparent;
  font-size: 1.4rem;
  /* z-index: 1000; */
  /* box-shadow: 1px 1px 5px grey; */
  padding: 0 1.9rem;
  font-family: Belleza;
}

.menu {
  display: flex;
  align-items: center;
  /* margin: auto 4rem; */
  z-index: 20;
}

.menu-items {
  display: flex;
  /* gap: 30px; */
  cursor: pointer;
}
.menu-item {
  width: 120px;
  height: 100%;
}

.menu-item:hover {
  background-color: rgb(63, 63, 187);
  color: white;
}

.logo {
  object-fit: cover;
  width: 250px;
  margin: 0 -50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.side-menu-button {
  display: inline-block;
  cursor: pointer;
}

.vertical-line {
  border: 1px solid black;
  margin: 14px 0px;
  border-radius: 5px;
}

@media screen and (max-width: 700px) {
  .header {
    justify-content: center;
  }

  .logo {
    width: 50%;
    margin: 0;
  }
}
